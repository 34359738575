import React from 'react';
import { TemplateSwitch } from 'src/templates';
import { marketingCmsClient } from 'src/lib/contentful-client';
import {
  ContentfulTemplates,
  CmsComponents,
  ContentfulLocales,
} from 'src/lib/constants';
import { generateBlogExcerptProps } from 'src/lib/utils';
import { optimizeContentfulImages } from 'src/lib/image-helpers';
import {
  makeConsolidatedPathsFromResponse,
  makeNewTemplateRequestsForAllLocales,
} from 'src/lib/contentful-utils';

const StaticCmsPage = (props) => {
  return <TemplateSwitch {...props} />;
};

// https://nextjs.org/docs/basic-features/data-fetching#getstaticprops-static-generation
export async function getStaticPaths() {
  // we fetch from the legacy landing page which has been superseded by the new template below
  // https://www.contentful.com/developers/docs/references/content-delivery-api/#/reference/entries
  // Contentful has a default limit of 100 entries, we set it to the maximum of 1000 entries so that
  // all U.S. landing pages can be statically generated. In the event we exceed 1000 entries we will
  // need to implement a while loop to fetch all results per https://jira.plaid.com/browse/SITE-4566
  const legacyTemplateRequest = marketingCmsClient.getEntries({
    limit: 1000,
    content_type: ContentfulTemplates.LANDING_PAGE,
    'fields.locale': 'US',
  });
  // we fetch from the new template for every supported locale
  // locales must first be enabled in Contentful and then added to the
  // ContentfulLocales set in src/lib/constants.ts
  const newTemplateRequests = makeNewTemplateRequestsForAllLocales({
    // UPDATE: for now we only want US pages queried here until the [id] folder is removed
    // once it has been removed we can remove the object being passed to makeNewTemplateRequestsForAllLocales
    locale: 'en-US',
    // exclude homepage
    'fields.url[nin]': 'homepage',
  });
  const responses = await Promise.all([
    legacyTemplateRequest,
    ...newTemplateRequests,
  ]);
  // we create an array of paths for the legacy template and new template
  // the new template has localization built in
  const paths = makeConsolidatedPathsFromResponse({
    responses,
    makeLegacyPathParams: (entry) => {
      return { id: [entry.fields.pageSlug] };
    },
    makeNewPathParams: (entry) => {
      //TODO: remove this when the migration of customer stories to v2 is complete
      if (
        entry?.fields?.url &&
        JSON.stringify(entry?.fields?.url) ===
          JSON.stringify(['customer-stories'])
      ) {
        return null;
      }

      // prepend the locale code for all locales except en-US
      const directories = entry?.fields?.url || [];

      if (entry.sys.locale !== 'en-US') {
        directories.unshift(entry.sys.locale.toLowerCase());
      }

      return { id: directories };
    },
  });

  return {
    paths,
    fallback: false,
  };
}

const mapEntryToProps = ({ entry, preview }) => {
  return {
    props: { ...optimizeContentfulImages(entry), preview: !!preview },
  };
};

const pathsAreIdentical = (arr1, arr2) => {
  if (arr1.length !== arr2.length) {
    return false;
  }
  return arr1.every((element, index) => {
    return element === arr2[index];
  });
};

export async function getStaticProps({ params, preview }) {
  const { id: path } = params;
  const legacyTemplateRequest = marketingCmsClient.getEntries({
    content_type: ContentfulTemplates.LANDING_PAGE,
    include: 10,
    'fields.locale': 'US',
    'fields.pageSlug': path[0],
    order: 'sys.createdAt', // To ensure the first entry in the array is the first one created with this slug
  });

  const newTemplateRequests = makeNewTemplateRequestsForAllLocales({
    'fields.url[all]': path
      .filter((path) => {
        // removes locale from query
        return !new RegExp(Array.from(ContentfulLocales).join('|'), 'i').test(
          path,
        );
      })
      .join(','),
  });

  const [legacyEntries, ...responses] = await Promise.all([
    legacyTemplateRequest,
    ...newTemplateRequests,
  ]);

  // we have to look through an array of responses to find the entry based on the current path
  const localizedResponse = responses.find((entry) => {
    const currentLocale = params.id[0];
    const isUs = !new RegExp(Array.from(ContentfulLocales).join('|'), 'i').test(
      currentLocale,
    );
    // we return the en-US entry if there is no current locale
    if (isUs && entry?.items[0]?.sys.locale === 'en-US') {
      return entry;
    }
    // else we return the first matching entry for this locale
    if (new RegExp(currentLocale, 'i').test(entry.items[0]?.sys.locale)) {
      return entry;
    }
    // otherwise we return nothing and fallback to a legacy entry, or nothing
  });

  // we look through the localizedResponse to find the matching contentful entry
  // there can be many paths that share similar keywords
  const matchingEntry = localizedResponse?.items.find((page) => {
    return pathsAreIdentical(page.fields.url, path);
  });

  // we short circuit to the new template entries if they exist
  // otherwise, fallback to the legacy entries
  const entry = matchingEntry || legacyEntries.items[0];

  // we check to see if the page has a BLOG_POST_EXCERPT component
  // if so we then make a request for some additional blog post meta data
  const blogPostComponent = entry?.fields?.components?.find((component) => {
    return (
      component.sys?.contentType?.sys?.id === CmsComponents.BLOG_POST_EXCERPT
    );
  });
  if (!blogPostComponent) {
    return mapEntryToProps({ entry, preview });
  }

  try {
    const components = await generateBlogExcerptProps(entry, blogPostComponent);
    entry.fields.components = components;
    return mapEntryToProps({ entry: entry, preview });
  } catch {
    return mapEntryToProps({ entry, preview });
  }
}

export default StaticCmsPage;
